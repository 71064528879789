<template>
  <div>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-btn
          color="#546E7A"
          outlined
          :href="`//dgfmapi.minedu.gob.bo/${tactivity}/guia.pdf`"
          target="_blank"
          download
        >
          Descargar
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mb-6 text-center">
      <v-col cols="12" sm="12">
        <object
          :data="`//dgfmapi.minedu.gob.bo/${tactivity}/guia.pdf`"
          width="100%"
          height="100%"
          type="application/pdf"
          style="height: 80vh"
        >
          El archivo no se puede ver, se recomienda descargar
        </object>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  export default {
    props: ["tactivity"],
  };
</script>
